import dayjs from 'dayjs'
import { LinkButton } from 'design/components/Typography/Typography'
import React from 'react'
import { TEST_ID } from '~/constants/cypress'
import RestrictionBillDateModal from '~/routes/AccountDeals/RestrictionBillDateModal'
import { Box, BoxProps, Text, rem } from '@butcherbox/freezer'
import * as Styles from './RestrictionBanner.css'

function RestrictionBanner(props: BoxProps) {
  const [showRestrictionModal, setShowRestrictionModal] = React.useState(false)
  const handleModalClose = React.useCallback(() => {
    setShowRestrictionModal(false)
  }, [])
  const handleModalOpen = React.useCallback(() => {
    setShowRestrictionModal(true)
  }, [])

  return (
    <>
      {showRestrictionModal && (
        <RestrictionBillDateModal
          expireDate={dayjs().add(90, 'd').format('YYYY-MM-DD')}
          handleModalClose={handleModalClose}
          sourceFrom="banner"
        />
      )}
      <Box className={Styles.Banner} {...props}>
        <Text color="white" variant="Body1Regular">
          Member deals are only available if your bill date is within the next
          90 days. To add member deals,
          <LinkButton
            data-cy={TEST_ID.BUTTON_RESTRICTION_BANNER}
            ml={rem(5)}
            onClick={() => handleModalOpen()}
          >
            <span style={{ color: 'white', textDecoration: 'underline' }}>
              update your next box bill date here.
            </span>
          </LinkButton>
        </Text>
      </Box>
    </>
  )
}

export default RestrictionBanner
