import * as Styles from './BottomNavigationCard.css'
import {
  Box,
  TextLink,
  CardMedia,
  Text,
  Card,
  CardBody,
  CardAction,
} from '@butcherbox/freezer'
import React from 'react'
import { BottomNavigationCardProps } from './BottomNavigationCard.types'

export const BottomNavigationCard: React.FC<BottomNavigationCardProps> = ({
  imgURL,
  imgAlt,
  cardTitle,
  cardDescription,
  ctaCopy,
  ctaLink,
  dataWhat,
  ctaDataWhat,
}) => {
  return (
    <Card
      className={Styles.bottomNavigationCard}
      data-what={dataWhat}
      display="flex"
      flexDirection="row"
      minHeight={216}
      width="fluid"
    >
      <Box
        className={Styles.bottomNavigationCardImg}
        display="flex"
        flexGrow={1}
      >
        <CardMedia
          alt={imgAlt}
          className={Styles.bottomNavigationCardImg}
          flexGrow={1}
          height={176}
          src={imgURL}
        />
      </Box>
      <Box
        className={Styles.bottomNavigationCardCopy}
        display="flex"
        flexDirection="column"
      >
        <CardBody textAlign="left" variant="compact">
          <Text variant="H2Bold">{cardTitle}</Text>
          <Text variant="Body2Bold">{cardDescription}</Text>
        </CardBody>
        <CardAction textAlign="left">
          <TextLink data-what={ctaDataWhat} href={ctaLink} variant="Subhead2">
            {ctaCopy}
          </TextLink>
        </CardAction>
      </Box>
    </Card>
  )
}

export default BottomNavigationCard
