import { Box, VisuallyHidden } from '@butcherbox/freezer'
import { RouteComponentProps, useLocation } from '@reach/router'
import LoadingSpinner from 'design/components/LoadingSpinner/LoadingSpinner'
import { ToastContext } from 'design/contexts/Toast/Toast.context'
import get from 'dlv'
import qs from 'querystringify'
import React from 'react'
import { useQuery } from 'react-query'
import { segmentIdentify } from '~/analytics/identifiers'
import { CACHE_KEY_MEMBER_DEAL_LANDER } from '~/bb-api/constants'
import { GET_DEALS } from '~/bb-api/endpoints'
import { LanderDeal } from '~/bb-api/schemata'
import ModalPastDue from '~/components/AccountPastDueModal'
import { useRestrictionDealHeroBanner } from '~/components/DealCard/useRestrictionDeal'
import { SubscriptionContextRemote } from '~/context/subscriptionRemote'
import { UserContext } from '~/context/user'
import useUpcomingOrder from '~/hooks/useUpcomingOrder'
import DealLander from '~/routes/AccountDeals/DealLander'
import Deals from '~/routes/AccountDeals/Deals'
import DealsGridLayout from '~/routes/AccountDeals/DealsGridLayout/DealsGridLayout'
import ErrorBoundary from '~/sections/ErrorBoundary'
import axios from '~/utils/axios'
import BottomNavigation from './BottomNavigation/BottomNavigation'
import DealsFooter from './DealsFooter/DealsFooter'
import { CatalogVariant } from './DealsGridLayout/DealGridLayout.types'
import DealsHero from './DealsHero/DealsHero'
import RestrictionBanner from './RestrictionBanner/RestrictionBanner'

type State = {
  landerVariant: string
  landerQueryEnabled: boolean
  showLanderDeal: boolean
  showDeals: boolean
  showHero: boolean
}

type DealsReducerActionTypes =
  | { type: 'makeLanderDealActive' }
  | { type: 'makeBaseDealPageActive' }
  | { type: 'initializeLander'; lander: string }

const initialState: State = {
  landerVariant: '',
  landerQueryEnabled: false,
  showLanderDeal: false,
  showDeals: false,
  showHero: false,
}

const reducer = (
  state: State,
  action: DealsReducerActionTypes
): Partial<State> => {
  switch (action.type) {
    case 'makeLanderDealActive':
      return {
        ...state,
        showLanderDeal: true,
        landerQueryEnabled: true,
        showDeals: false,
        showHero: true,
      }
    case 'makeBaseDealPageActive':
      return {
        ...state,
        showLanderDeal: false,
        landerQueryEnabled: false,
        showDeals: true,
        showHero: true,
        landerVariant: '',
      }
    case 'initializeLander':
      return {
        ...state,
        landerQueryEnabled: true,
        landerVariant: action.lander,
      }
  }
}

const AccountDeals: React.FC<RouteComponentProps> = () => {
  // Account Past Due Modal Handling
  const [modalOpen, setModalOpen] = React.useState(false)
  const [isError, setIsError] = React.useState(false)
  const handleModalClose = React.useCallback(() => setModalOpen(false), [])
  const displayPastDueModal = React.useCallback((display) => {
    setModalOpen(display)
  }, [])

  const user = React.useContext(UserContext)
  const showToast = React.useContext(ToastContext)
  const { subscription } = React.useContext(SubscriptionContextRemote)

  const userId = get(user, 'id')

  const location = useLocation()

  // Should Restricted Banner Be Shown?
  const { showRestrictedHeroBanner } = useRestrictionDealHeroBanner()

  const subscription_status =
    subscription !== undefined ? subscription.status : 'not_found'

  const {
    data: upcomingOrder,
    isLoading: upcomingOrderIsLoading,
  } = useUpcomingOrder()

  // Create a map for faster lookup of quantity in individual deal cards
  const upcomingOrderMap = React.useMemo(
    () =>
      upcomingOrder?.invoiceItems.reduce((obj, invoiceItem) => {
        obj[invoiceItem.sku] = invoiceItem
        return obj
      }, {}) || {},
    [upcomingOrder?.invoiceItems]
  )

  const [
    { showLanderDeal, showDeals, landerVariant, showHero, landerQueryEnabled },
    dispatch,
  ] = React.useReducer(reducer, initialState)

  const { isLoading, data: landerDeals } = useQuery(
    [CACHE_KEY_MEMBER_DEAL_LANDER, landerVariant],
    () =>
      axios
        .get<{ data: LanderDeal[] }>(`${GET_DEALS(userId)}/${landerVariant}`)
        .then((response) => {
          return response.data.data
        }),
    {
      enabled: !upcomingOrderIsLoading && landerQueryEnabled,
      staleTime: 5000,
      onSuccess: (data: LanderDeal[]) => {
        // If no lander deals or query has since been turned off, fall back to normal deals
        if (data.length === 0 || landerQueryEnabled === false) {
          dispatch({ type: 'makeBaseDealPageActive' })
        } else {
          /* For single deal landing pages, check if the user already has the max quantity of
          a deal in their box, or already has it as a for-life offer. */
          const onlyOneDealOnPage = data.length === 1

          if (onlyOneDealOnPage) {
            const [deal] = data

            // Need to match on sku for regular deals that may allow for quantities > 1
            // and offerGroup for for-life offers
            const dealInOrder = upcomingOrder?.invoiceItems.find(
              (x) =>
                x.sku === deal.sku ||
                (x.offerGroup !== null && x.offerGroup === deal.offerGroup)
            )

            // Check if existing offerGroup is not null and matches deal offerGroup
            const hasForLifeOffer =
              upcomingOrder && deal.offerGroup
                ? upcomingOrder.subscription.box.offers.find(
                    (x) => x.offerGroup === deal.offerGroup
                  )
                : false

            const hasMaxQuantityInOrder =
              dealInOrder?.quantity >= deal.maxQuantity

            if (hasMaxQuantityInOrder || hasForLifeOffer) {
              dispatch({ type: 'makeBaseDealPageActive' })
              showToast('error', {
                children:
                  "You've already added this deal to your next box. Check out additional deals below!",
                duration: 7000,
              })
            } else {
              dispatch({ type: 'makeLanderDealActive' })
            }
          } else {
            dispatch({ type: 'makeLanderDealActive' })
          }
        }
      },
      onError: (e: any) => {
        if (e.status === 404) {
          history.pushState({}, `Deals`, `/account/deals`)
          dispatch({ type: 'makeBaseDealPageActive' })
        } else {
          setIsError(true)
        }
      },
    }
  )

  React.useEffect(() => {
    if (user) {
      segmentIdentify({
        userId: user.id.toString(),
        traits: {
          email: user.emailAddress,
          distribution_center: user.shipZone?.preferredFacility,
          first_name: user.firstName,
          last_name: user.lastName,
        },
      })
    }
  }, [user])

  // If valid lander query param, set the variant and enable query
  React.useEffect(() => {
    const { lander } = qs.parse(location.search) as {
      lander: string
    }

    if (lander) {
      dispatch({ type: 'initializeLander', lander })
    } else {
      dispatch({ type: 'makeBaseDealPageActive' })
    }
  }, [location.search])

  /* Manually add the lander page to the history stack when
  showLanderDeal changes so that the lander variant will be accessible
  using back button if user navigates to main deals page */
  React.useEffect(() => {
    if (showLanderDeal && landerVariant) {
      history.pushState(
        {},
        `Deals ${landerVariant}`,
        `?lander=${landerVariant}`
      )
    }
  }, [showLanderDeal, landerVariant])

  const [isAllExternalDeals, setIsAllExternalDeals] = React.useState(false)
  React.useEffect(() => {
    if (typeof landerDeals !== 'undefined') {
      setIsAllExternalDeals(
        landerDeals.every((deal) => deal.shouldRedirect === true)
      )
    }
  }, [isAllExternalDeals, setIsAllExternalDeals, landerDeals])

  return isError ? (
    <ErrorBoundary />
  ) : (
    <>
      {showHero && (
        <DealsHero landerDeals={landerDeals} showLanderDeal={showLanderDeal} />
      )}

      <VisuallyHidden>
        <p>
          Member deals are one-time products that can be added to your upcoming
          box order
        </p>
        <h2>Products</h2>
      </VisuallyHidden>

      {showRestrictedHeroBanner &&
        (!showLanderDeal ||
          (landerDeals !== undefined && landerDeals.length !== 1)) && (
          <RestrictionBanner />
        )}

      {showLanderDeal &&
        (landerDeals !== undefined && landerDeals.length === 1 ? (
          <DealLander
            displayPastDueModal={displayPastDueModal}
            landerDeal={landerDeals[0]}
            subscriptionStatus={subscription_status}
            upcomingOrderMap={upcomingOrderMap}
          />
        ) : (
          <Box
            background="ivory"
            paddingTop={landerVariant === CatalogVariant ? 0 : 48}
          >
            <DealsGridLayout
              deals={landerDeals}
              displayPastDueModal={displayPastDueModal}
              landerVariant={landerVariant}
              loading={isLoading}
              productListCategory={`memberDeal-dealsLander-${landerVariant}`}
              subscriptionStatus={subscription_status}
              upcomingOrderMap={upcomingOrderMap}
            />
          </Box>
        ))}

      {showDeals && (
        <Box paddingTop={48}>
          <Deals upcomingOrderMap={upcomingOrderMap} />
        </Box>
      )}
      {(showDeals ||
        showRestrictedHeroBanner ||
        showHero ||
        showLanderDeal ||
        showHero) &&
      !isLoading ? (
        <>
          <DealsFooter
            disclaimerText={
              showLanderDeal &&
              landerDeals[0].disclaimerText !== null &&
              landerDeals[0].disclaimerText
            }
            isAllExternalDeals={isAllExternalDeals}
          />
          <BottomNavigation landerVariant={landerVariant} />
          <ModalPastDue
            handleModalClose={handleModalClose}
            isDisplayModal={modalOpen}
          />
        </>
      ) : (
        <LoadingSpinner />
      )}
    </>
  )
}

export default AccountDeals
