import React from 'react'
import { Lockup, Text } from '@butcherbox/freezer'
import * as Styles from './DealsFooter.css'
import {
  LinkExternal,
  LinkInternal,
} from 'design/components/Typography/Typography'
import useOptimizelyFeature from '~/hooks/useOptimizelyFeature'

const DealsFooter = ({
  disclaimerText,
  isAllExternalDeals,
}: {
  disclaimerText?: string
  isAllExternalDeals?: boolean
}) => {
  const [isSpecialtyIngredientPageRedirect] = useOptimizelyFeature(
    'shop-813_specialty_ingredients_traffic_to_remix_url'
  )
  return (
    <>
      <Lockup className={Styles.Footer}>
        {!isAllExternalDeals && (
          <>
            <LinkExternal
              href={
                isSpecialtyIngredientPageRedirect
                  ? 'https://shop.butcherbox.com/specialty-food-ingredients/'
                  : 'https://www.butcherbox.com/specialty-food-ingredients/'
              }
              target="_self"
            >
              View Specialty Food Ingredients
            </LinkExternal>
            <Text variant="Body1Regular">
              {`Member deals will be included in your next box, and are only available within 90 days of your next bill date unless otherwise noted. To view or change your bill date, visit your `}
              <LinkInternal
                href="/account/box-settings"
                title="View or change your delivery date"
              >
                box settings
              </LinkInternal>
              .
            </Text>
          </>
        )}
        {disclaimerText && <Text variant="Body1Regular">{disclaimerText}</Text>}
      </Lockup>
    </>
  )
}

export default DealsFooter
