import {
  Box,
  CardAction,
  Lockup,
  rem,
  Text,
  TextLink,
} from '@butcherbox/freezer'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Disclaimer from '~/cms/components/disclaimer'
import { useRestrictionDealHeroBanner } from '~/components/DealCard/useRestrictionDeal'
import RestrictionBanner from '~/routes/AccountDeals/RestrictionBanner/RestrictionBanner'
import { formatPriceFromCents, formatStringNoOrphans } from '~/utils'
import DealActionButton from '../DealCard/DealCardActionButton'
import * as Styles from './ModuleProductHero.css'
import { ModuleProductHeroProps } from './ModuleProductHero.types'
import useIngredients from '~/hooks/useIngredients'
import CutInfoModal from '../CutInfoModal/CutInfoModal'
import * as ActionButtonStyles from '~/components/DealCard/ActionButtonModalStyles.css'

const ModuleProductHero: React.FC<ModuleProductHeroProps> = ({
  landerDeal,
  isBanner = true,
  disclaimer = '',
  displayPastDueModal,
  subscriptionStatus,
  upcomingOrderMap,
  ...props
}) => {
  const {
    dealName,
    dealPrice,
    dealDescription,
    dealImage,
    isSoldOut,
    strikethroughPrice,
  } = landerDeal

  const ingredients = useIngredients()
  const { showRestrictedHeroBanner } = useRestrictionDealHeroBanner()

  const imageData = useStaticQuery(graphql`
    query {
      soldOut: file(relativePath: { eq: "soldout.png" }) {
        childImageSharp {
          fluid(cropFocus: CENTER) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const img = isSoldOut
    ? imageData.soldOut?.childImageSharp.fluid.src
    : dealImage

  const [modalOpen, setModalOpen] = React.useState(false)

  return (
    <Box className={Styles.Container} {...props}>
      <Box
        className={isBanner ? Styles.Contents.banner : Styles.Contents.default}
      >
        {showRestrictedHeroBanner && (
          <Box className={Styles.RestrictionBannerContainer}>
            <RestrictionBanner />
          </Box>
        )}
        <Box
          className={isSoldOut ? Styles.DealImageSoldOut : Styles.DealImage}
          style={{
            backgroundImage: `url(${img})`,
          }}
        />
        <Lockup className={Styles.DealText}>
          <Text component="h1" variant="DisplayOne">
            {formatStringNoOrphans(dealName)}
          </Text>
          <Box alignItems="flexEnd" display="flex" marginBottom={8}>
            <Text color="spicedCrimson" variant="DisplayOne">
              {formatPriceFromCents(dealPrice)}
            </Text>
            {strikethroughPrice != dealPrice && strikethroughPrice > 0 ? (
              <Text
                color="stone"
                display="flex"
                marginLeft={8}
                variant="H1Bold"
              >
                <span style={{ textDecoration: 'line-through' }}>
                  {formatPriceFromCents(strikethroughPrice)}
                </span>
              </Text>
            ) : null}
          </Box>
          <Text component="h2" variant="H2Bold">
            <span dangerouslySetInnerHTML={{ __html: dealDescription }}></span>
          </Text>
          <Box
            alignItems="flexEnd"
            display="flex"
            paddingBottom={isSoldOut ? 0 : 16}
          >
            {ingredients(landerDeal).length > 0 && (
              <TextLink
                component="button"
                onClick={() => {
                  setModalOpen(true)
                }}
                theme="slate"
                underline={true}
              >
                View Ingredients
              </TextLink>
            )}
            {landerDeal && landerDeal.maxQuantity < 10 && (
              <>
                {ingredients(landerDeal).length > 0 && (
                  <span className={Styles.MiddleDotStyles}></span>
                )}
                <Text variant="Body1Regular">
                  Limit {landerDeal.maxQuantity} per person
                </Text>
              </>
            )}
          </Box>

          {!isSoldOut && (
            <CardAction>
              <DealActionButton
                deal={landerDeal}
                dealType="featuredDeal"
                displayPastDueModal={displayPastDueModal}
                key={`${landerDeal.sku}`}
                subscriptionStatus={subscriptionStatus}
                upcomingOrderMap={upcomingOrderMap}
              />
            </CardAction>
          )}
          {disclaimer && (
            <Disclaimer color="bb.stone" fontSize={rem(16)}>
              {disclaimer}
            </Disclaimer>
          )}
        </Lockup>
      </Box>
      {modalOpen ? (
        <CutInfoModal
          onClose={() => setModalOpen(false)}
          product={landerDeal}
          quantity={landerDeal.quantity}
          renderButton={() => (
            <Box className={ActionButtonStyles.ActionButtonModalStyles} py={12}>
              <DealActionButton
                deal={landerDeal}
                displayPastDueModal={displayPastDueModal}
                key={`${landerDeal.sku}`}
                subscriptionStatus={subscriptionStatus}
                upcomingOrderMap={upcomingOrderMap}
              />
            </Box>
          )}
          variant="deal"
        ></CutInfoModal>
      ) : null}
    </Box>
  )
}

export default ModuleProductHero
