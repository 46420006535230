import React from 'react'
import useDeals from '~/hooks/useDeals'
import useUpcomingOrder from '~/hooks/useUpcomingOrder'
import DealsGridLayout from '~/routes/AccountDeals/DealsGridLayout/DealsGridLayout'
import { SubscriptionContextRemote } from '~/context/subscriptionRemote'
import ModalPastDue from '~/components/AccountPastDueModal'

type IDealsProps = {
  upcomingOrderMap: object
}

const Deals: React.FC<IDealsProps> = ({ upcomingOrderMap }) => {
  const { data: deals = [], status: getDealsStatus } = useDeals()
  const { status: getUpcomingOrderStatus } = useUpcomingOrder()

  const loading =
    getDealsStatus === 'loading' || getUpcomingOrderStatus === 'loading'

  const { subscription } = React.useContext(SubscriptionContextRemote)
  const subscription_status =
    subscription !== undefined ? subscription.status : 'not_found'

  const [modalOpen, setModalOpen] = React.useState(false)
  const displayPastDueModal = React.useCallback(
    (display) => {
      setModalOpen(display)
    },

    []
  )
  const handleModalClose = React.useCallback(() => setModalOpen(false), [])

  return (
    <>
      {getDealsStatus === 'error' &&
        'Sorry, something went wrong retrieving deals.'}
      {
        <>
          <DealsGridLayout
            deals={deals}
            displayPastDueModal={displayPastDueModal}
            loading={loading}
            productListCategory="memberDeal-dealsPage"
            subscriptionStatus={subscription_status}
            upcomingOrderMap={upcomingOrderMap}
          />
          <ModalPastDue
            handleModalClose={handleModalClose}
            isDisplayModal={modalOpen}
          />
        </>
      }
    </>
  )
}

export default Deals
