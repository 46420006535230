import { styled } from 'design'
import { Box, Text, Lockup, rem } from '@butcherbox/freezer'
import React from 'react'
import ImageSetBackground from '~/components/ImageSetBackground/ImageSetBackground'
import { DealsHeroProps, HeroOverlayProps } from './DealsHero.types'
import * as Styles from './DealsHero.css'
import useDealHeroContent from './hooks/useDealHeroContent'

const HERO_HEIGHT = 252

function DealsHero({ landerDeals, showLanderDeal }: DealsHeroProps) {
  const contents = useDealHeroContent({ landerDeals, showLanderDeal })

  const singleLanderDeal = landerDeals?.length === 1

  return showLanderDeal && landerDeals && landerDeals[0] ? (
    <Box
      className={
        singleLanderDeal
          ? Styles.HeroImageLander.singleDeal
          : Styles.HeroImageLander.multiDeal
      }
      style={{ backgroundImage: `url(${contents.backgroundImage})` }}
    >
      <HeroOverlay display={contents.display} text={contents.text} />
    </Box>
  ) : (
    <HeroImage imageSet={contents.imageSet}>
      <HeroOverlay display={contents.display} text={contents.text} />
    </HeroImage>
  )
}

const HeroOverlay: React.FC<HeroOverlayProps> = ({ display, text }) => {
  return (
    <Lockup className={Styles.HeroOverlay}>
      <h1>
        <Text color="white" component="span" variant="DisplayOne">
          {`${display}`}
        </Text>
      </h1>
      <Text color="white" variant="Subhead1">
        {text}
      </Text>
    </Lockup>
  )
}

const HeroImage = styled(ImageSetBackground)`
  height: ${rem(HERO_HEIGHT)};
  width: 100%;
  img {
    filter: brightness(50%);
  }
`

export default DealsHero
