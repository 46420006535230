import React from 'react'
import { LanderDeal } from '~/bb-api/schemata'
import ModuleProductHero from '~/components/ModuleProductHero/ModuleProductHero'

export type IDealLanderProps = {
  landerDeal: LanderDeal
  upcomingOrderMap: object
  subscriptionStatus: string
  displayPastDueModal: (display: boolean) => void
}

const DealLander: React.FC<IDealLanderProps> = ({
  landerDeal,
  upcomingOrderMap,
  subscriptionStatus,
  displayPastDueModal,
}) => {
  const disclaimer = ''

  return (
    <ModuleProductHero
      disclaimer={disclaimer}
      displayPastDueModal={displayPastDueModal}
      landerDeal={landerDeal}
      paddingBottom={4}
      subscriptionStatus={subscriptionStatus}
      upcomingOrderMap={upcomingOrderMap}
    />
  )
}

export default DealLander
