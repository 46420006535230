import * as Styles from './BottomNavigation.css'
import { Box, Text } from '@butcherbox/freezer'
import React from 'react'
import BottomNavigationCard from './BottomNavigationCard/BottomNavigationCard'
import { BottomNavigationProps } from './BottomNavigation.types'
import useOptimizelyFeature from '~/hooks/useOptimizelyFeature'

export const BottomNavigation: React.FC<BottomNavigationProps> = ({
  landerVariant,
}) => {
  const [isMarketplacePageEnabled] = useOptimizelyFeature(
    'shop_788_gatsby_marketplace_redirect_to_remix'
  )
  const mainDealsPageCards = [
    {
      imgURL:
        'https://www.butcherbox.com/wp-content/uploads/2015/07/member-deal-card.jpg',
      imgAlt: 'Deals Card',
      cardTitle: 'Shop Member Deals',
      cardDescription: 'Return to our deals page for great savings!',
      ctaCopy: 'shop now',
      ctaLink: '/account/deals',
      dataWhat: 'Member-Deals-Link-Card',
      ctaDataWhat: 'Member-Deals-Link-Card-CTA',
      displayPages: ['catalog', 'marketplace'],
    },
    {
      imgURL:
        'https://www.butcherbox.com/wp-content/uploads/2015/07/catalog-card-new.jpg',
      imgAlt: 'Catalog Card',
      cardTitle: 'The Catalog',
      cardDescription:
        'Find your perfect cut by browsing our catalog of products.',
      ctaCopy: 'shop now',
      ctaLink: '/account/deals?lander=catalog',
      dataWhat: 'Catalog-Deals-Link-Card',
      ctaDataWhat: 'Catalog-Deals-Link-Card-CTA',
      displayPages: ['', 'marketplace'],
    },
    {
      imgURL:
        'https://www.butcherbox.com/wp-content/uploads/2015/07/marketplace-card.jpg',
      imgAlt: 'Marketplace Card',
      cardTitle: 'ButcherBox Marketplace',
      cardDescription:
        "Check out some of our staff's favorite cooking products!",
      ctaCopy: 'shop now',
      ctaLink: isMarketplacePageEnabled
        ? 'https://shop.butcherbox.com/marketplace'
        : '/account/deals?lander=marketplace',
      dataWhat: 'Marketplace-Deals-Link-Card',
      ctaDataWhat: 'Marketplace-Deals-Link-Card-CTA',
      displayPages: ['', 'catalog'],
    },
  ]

  const displayPagesList = ['', 'catalog', 'marketplace']
  return (
    <>
      {displayPagesList.includes(landerVariant) && (
        <Box
          className={Styles.bottomNavigationSectionWrapper}
          data-where="Catalog-Card-Section"
        >
          <Box className={Styles.bottomNavigationWrapper}>
            <div className={Styles.bottomNavigationSectionHeading}>
              <Text
                marginBottom={8}
                textAlign="center"
                textTransform="uppercase"
                variant="Subhead1"
              >
                don't miss out
              </Text>
              <Text marginBottom={24} textAlign="center" variant="Title">
                Shop All Items
              </Text>
            </div>

            <Box className={Styles.bottomNavigationCardGrid}>
              {mainDealsPageCards
                .filter(function (elem) {
                  return elem.displayPages.includes(landerVariant)
                })
                .map((item, idx) => (
                  <BottomNavigationCard {...item} key={idx} />
                ))}
            </Box>
          </Box>
        </Box>
      )}
    </>
  )
}

export default BottomNavigation
