import { graphql, useStaticQuery } from 'gatsby'
import { CMS } from '~/cms/types'
import { DealsHeroProps } from '../DealsHero.types'

const useDealHeroContent = ({
  landerDeals,
  showLanderDeal,
}: DealsHeroProps): {
  display: string
  text: string
  imageSet: CMS.ImageSet | null
  backgroundImage: string | null
} => {
  const imageData = useStaticQuery(graphql`
    query {
      bg: contentfulImageSet(
        uniqueId: { eq: "member_deals_hero_background_image" }
      ) {
        ...ImageSet
      }
    }
  `)
  const isSingleLanderDeal = landerDeals?.length === 1

  return {
    display:
      landerDeals && landerDeals[0]
        ? landerDeals[0].dealLanderHeroHeading
        : `Member Deal${isSingleLanderDeal ? '' : 's'}`,
    text:
      showLanderDeal && landerDeals && landerDeals[0]
        ? landerDeals[0].headerText
        : 'Only available in your next box.',
    imageSet:
      showLanderDeal && landerDeals && landerDeals[0] ? null : imageData.bg,
    backgroundImage:
      showLanderDeal && landerDeals && landerDeals[0]
        ? landerDeals[0].backgroundHeroImage
        : null,
  }
}

export default useDealHeroContent
